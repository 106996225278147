import React from 'react';
import FeatureList from "../components/FeatureList";
import Grid from "@material-ui/core/Grid";
import {HeaderBanner} from "../components/HeaderBanner";
import {PhotoGallery} from "../components/PhotoGallery";

const LivingSpace = () => (
    <FeatureList label={'Living Space'} features={[
        {label: 'Air Conditioning'},
        {label: 'Ceiling Fan(s)'},
        {label: 'Hardwood Flooring'},
        {label: 'Oversized Closets'},
        {label: 'Washer & Dryer In Unit'},
        {label: 'Perks'},
        {label: 'Cable Ready'},

    ]} />
);

const Kitchen = () => (

    <FeatureList label={'Kitchen'} features={[

        {label: 'Dishwasher'},
        {label: 'Microwave'},
        {label: 'Refrigerator'},
        {label: 'Stove'},

    ]} />
);

const Outdoor = () => (
    <FeatureList label={'Outdoor Space'} features={[
        {label: 'Balcony'},
        {label: 'Deck'},
        {label: 'Patio'}
    ]} />
);

const Unique = () => (
    <FeatureList label={'Unique'} features={[
        {label: 'Attic'},
        {label: 'Blinds'},
        {label: 'Carpet'},
        {label: 'Ceramic Tile'},
        {label: 'Disposal'},
        {label: 'Fence Yard'},
        {label: 'Garden'},
        {label: 'Heat'},
        {label: 'High Speed Internet Available'},
        {label: 'Lawn'},
        {label: 'Living Room'},
        {label: 'Storage'},
        {label: 'Vinyl'}
    ]} />
)

const ammeneties = [
    Kitchen,
    LivingSpace,
    Outdoor,
    Unique
];

export const PropertyDetails = () => (
    <Grid container xs={12} spacing={1}>
        {ammeneties.map((Component) => (
            <Grid item xs={6}>
                <Component />
            </Grid>
        ))}
    </Grid>
)