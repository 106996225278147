import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {GalleryPage} from "./GalleryPage";
import FeatureList from "../components/FeatureList";
import {PropertyDetails} from "./PropertyDetails";
import Grid from "@material-ui/core/Grid";
import {HeaderBanner} from "../components/HeaderBanner";
import {PhotoGallery} from "../components/PhotoGallery";
import {Button, IconButton, Link, Toolbar} from "@material-ui/core";
import EmailLink from "../components/EmailLink";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
//
// const useStyles = makeStyles((theme: Theme) => ({
//     root: {
//         flexGrow: 1,
//         backgroundColor: theme.palette.background.paper,
//     },
// }));

const primaryImageSrc = 'https://rentpath-res.cloudinary.com/t_rp,cs_tinysrgb,fl_force_strip,c_fill,f_webp,q_auto:low,dpr_2.0/e_unsharp_mask:50/if_iw_gt_ih,w_1000/if_else,w_1000,h_562,b_white,c_pad/b27511f5ef0d644ef45d487fd58c873d'

const Pages = [
    {
        title: 'Property details',
        page: () => (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6}>
                        <img src={primaryImageSrc} width={'100%'} />
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={12}>
                        <GalleryPage title={'Layout'} images={[
                            {href: './static/layouts/upstairs.svg'},
                            {href: './static/layouts/downstairs.svg'}
                        ]}/>
                    </Grid>
                    <Grid item xs={12}>
                        <PropertyDetails />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    },
    // {title: 'Layout', page: () => <GalleryPage title={'Layout'} images={[
    //         {href: './static/layouts/upstairs.svg'},
    //         {href: './static/layouts/downstairs.svg'}
    //     ]}/>},
    {title: 'Exterior', page: () => <GalleryPage title={'Exterior'} images={[
            {href: 'https://rentpath-res.cloudinary.com/t_rp,cs_tinysrgb,fl_force_strip,c_fill,f_webp,q_auto:low,dpr_2.0/e_unsharp_mask:50/if_iw_gt_ih,w_1000/if_else,w_1000,h_562,b_white,c_pad/b27511f5ef0d644ef45d487fd58c873d'},
            {href: 'https://rentpath-res.cloudinary.com/t_rp,cs_tinysrgb,fl_force_strip,c_fill,f_webp,q_auto:low,dpr_2.0/e_unsharp_mask:50/if_iw_gt_ih,w_1000/if_else,w_1000,h_562,b_white,c_pad/a60da16d08bc711df2d22f4dc35a85b9'},
            {href: 'https://rentpath-res.cloudinary.com/t_rp,cs_tinysrgb,fl_force_strip,c_fill,f_webp,q_auto:low,dpr_2.0/e_unsharp_mask:50/if_iw_gt_ih,w_1000/if_else,w_1000,h_562,b_white,c_pad/8dac0862435ea3325096267f97c7b80a'},
            {href: 'https://rentpath-res.cloudinary.com/t_rp,cs_tinysrgb,fl_force_strip,c_fill,f_webp,q_auto:low,dpr_2.0/e_unsharp_mask:50/if_iw_gt_ih,w_1000/if_else,w_1000,h_562,b_white,c_pad/56bcab015935c3f415ef8e73c3290585'},

        ]}/>},
    {title: 'Living Room', page: () => <GalleryPage title={'Living Room'} images={[
            {href: 'https://rentpath-res.cloudinary.com/t_rp,cs_tinysrgb,fl_force_strip,c_fill,f_webp,q_auto:low,dpr_2.0/e_unsharp_mask:50/if_iw_gt_ih,w_1000/if_else,w_1000,h_562,b_white,c_pad/62d9824b8caeeda13b297af148a8341e'},
            {href: 'https://rentpath-res.cloudinary.com/t_rp,cs_tinysrgb,fl_force_strip,c_fill,f_webp,q_auto:low,dpr_2.0/e_unsharp_mask:50/if_iw_gt_ih,w_1000/if_else,w_1000,h_562,b_white,c_pad/34bd5abe362235b33f429308685300ab'},
            {href: 'https://rentpath-res.cloudinary.com/t_rp,cs_tinysrgb,fl_force_strip,c_fill,f_webp,q_auto:low,dpr_2.0/e_unsharp_mask:50/if_iw_gt_ih,w_1000/if_else,w_1000,h_562,b_white,c_pad/3daa5e6cb806600ade19707a491dddfc'},

        ]}/>},
    {title: 'Kitchen', page: () => <GalleryPage title={'Kitchen'} images={[
            {href: 'https://rentpath-res.cloudinary.com/t_rp,cs_tinysrgb,fl_force_strip,c_fill,f_webp,q_auto:low,dpr_2.0/e_unsharp_mask:50/if_iw_gt_ih,w_1000/if_else,w_1000,h_562,b_white,c_pad/299958f4c14335055b8d95f9020392c5'},
            {href: 'https://ssl.cdn-redfin.com/photo/10/mbpaddedwide/047/genMid.40688047_4_0.jpg'},
            {href: 'https://rentpath-res.cloudinary.com/t_rp,cs_tinysrgb,fl_force_strip,c_fill,f_webp,q_auto:low,dpr_2.0/e_unsharp_mask:50/if_iw_gt_ih,w_1000/if_else,w_1000,h_562,b_white,c_pad/c6a2f032d450902a7005a28607c9ea29'}
        ]}/>},
    {title: 'Bedrooms', page: () => (
        Bedrooms.map(({page}) => page())
        )},
]

const Bedrooms = [
    {title: 'Bedroom 1', page: () => <GalleryPage title={'Bedroom 1 (Master Bedroom)'} images={[
            {href: 'https://rentpath-res.cloudinary.com/t_rp,cs_tinysrgb,fl_force_strip,c_fill,f_webp,q_auto:low,dpr_2.0/e_unsharp_mask:50/if_iw_gt_ih,w_1000/if_else,w_1000,h_562,b_white,c_pad/cdea7f31e6d5d7a6e64bfdd883ec0d29'},
            {href: 'https://rentpath-res.cloudinary.com/t_rp,cs_tinysrgb,fl_force_strip,c_fill,f_webp,q_auto:low,dpr_2.0/e_unsharp_mask:50/if_iw_gt_ih,w_1000/if_else,w_1000,h_562,b_white,c_pad/3ec66d37858bf1c86bf2183992d8fb39'},
            {href: 'https://rentpath-res.cloudinary.com/t_rp,cs_tinysrgb,fl_force_strip,c_fill,f_webp,q_auto:low,dpr_2.0/e_unsharp_mask:50/if_iw_gt_ih,w_1000/if_else,w_1000,h_562,b_white,c_pad/8e5044571d9ebbd485e2e2b6114ed749'},
            {href: 'https://rentpath-res.cloudinary.com/t_rp,cs_tinysrgb,fl_force_strip,c_fill,f_webp,q_auto:low,dpr_2.0/e_unsharp_mask:50/if_iw_gt_ih,w_1000/if_else,w_1000,h_562,b_white,c_pad/34890e52f4b89e832a4ef2a29a9333cf'},
            {href: 'https://rentpath-res.cloudinary.com/t_rp,cs_tinysrgb,fl_force_strip,c_fill,f_webp,q_auto:low,dpr_2.0/e_unsharp_mask:50/if_iw_gt_ih,w_1000/if_else,w_1000,h_562,b_white,c_pad/595fc3417ab6bd503954d2741f6ab8f0'},
        ]}/>},
    {title: 'Bedroom 2', page: () => <GalleryPage title={'Bedroom 2'} images={[
            {href: 'https://rentpath-res.cloudinary.com/t_rp,cs_tinysrgb,fl_force_strip,c_fill,f_webp,q_auto:low,dpr_2.0/e_unsharp_mask:50/if_iw_gt_ih,w_1000/if_else,w_1000,h_562,b_white,c_pad/073bf63e2354b73105995db5c1f13c00'},
            {href: 'https://rentpath-res.cloudinary.com/t_rp,cs_tinysrgb,fl_force_strip,c_fill,f_webp,q_auto:low,dpr_2.0/e_unsharp_mask:50/if_iw_gt_ih,w_1000/if_else,w_1000,h_562,b_white,c_pad/9e3174926e1c6ffd0dd6bd4a644fcc8f'}
        ]}/>},
    {title: 'Bedroom 3', page: () => <GalleryPage title={'Bedroom 3'} images={[
            {href: 'https://rentpath-res.cloudinary.com/t_rp,cs_tinysrgb,fl_force_strip,c_fill,f_webp,q_auto:low,dpr_2.0/e_unsharp_mask:50/if_iw_gt_ih,w_1000/if_else,w_1000,h_562,b_white,c_pad/51f8bf8886a0c73f6c7e5d7040f90729'},
            {href: 'https://rentpath-res.cloudinary.com/t_rp,cs_tinysrgb,fl_force_strip,c_fill,f_webp,q_auto:low,dpr_2.0/e_unsharp_mask:50/if_iw_gt_ih,w_1000/if_else,w_1000,h_562,b_white,c_pad/8a7662095d58e8779e80c55d1d645df3'},
        ]}/>},
    {title: 'Bedroom 4 (photos coming soon)', page: () => <GalleryPage title={'Bedroom 4'} images={[]}/>},
]


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        emailLink: {
            marginLeft: '10px'
        }
    }),
);

export function SimpleTabs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        2700 Somerset Ave. Castro Valley CA
                    </Typography>
                    <Link color="inherit" target={'_blank'} href={'https://apply.link/335YQKF'}>Apply Online</Link>
                    <span className={classes.emailLink}>
                        |
                    </span>
                    <span className={classes.emailLink}>
                        <EmailLink label={'Email us'} email={'kodysmith2010@gmail.com'} subject={'I would like more information about 2700 somerset ave.'} body={'Please send me more information about this property.'} />
                    </span>
                </Toolbar>
                <Tabs value={value} onChange={handleChange} aria-label="Home details tabs">
                    {Pages.map((tab, index) => (
                            <Tab label={tab.title} {...a11yProps(index)} />
                    ))}
                </Tabs>
            </AppBar>
            {Pages.map((tabPanel, index) => (
                <TabPanel index={index} value={value} >
                    <div>{tabPanel.page()}</div>
                </TabPanel>
            ))}
        </div>
    );
}
