import React from 'react';
import {Link} from "@material-ui/core";

type Props = {
    email: string;
    cc?: string;
    body?: string;
    subject?: string;
    label: string;
}
export default function EmailLink (props: Props) {
    const resultStr = `mailto:${props.email}?subject=${props.subject}&body=${props.body}`;
    return (
        <Link color="inherit" target={'_blank'} href={resultStr}>{props.label}</Link>
    )
}