import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";

import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {TitleBarImage, TitlebarImageList} from "./ImageList";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);


type PhotoItemProps = {
    title?: string,
    href: string,
    description?: string
}

type PhotoGalleryProps = {
    photoItem: PhotoItemProps[]
};

function PhotoItem ({title, href, description}: PhotoItemProps) {
    return (<img style={{width: '100%'}} alt={title} src={href} aria-describedby={description}/>)
}

export const PhotoGallery = ({photoItem}: PhotoGalleryProps) => {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = useState(0);
    if(photoItem && photoItem.length > 0) {
        return (
            <Grid className={classes.root} container alignItems="center" item xs={12} spacing={1}>
                {photoItem.map(({title, href, description}, index) =>
                    <Grid item xs={2}>
                        <button onClick={() => setSelectedIndex(index)} >
                            <PhotoItem title={title} href={href} description={description}/>
                        </button>
                    </Grid>
                )}
                {/*<TitlebarImageList title={'photos'} images={photoItem.map((imgObj): TitleBarImage => {*/}
                {/*    return {...imgObj, ...{img: imgObj.href, title: 'foo'}};*/}
                {/*})} />*/}
                <Grid item xs={12}>
                    <PhotoItem title={photoItem[selectedIndex].title} {...photoItem[selectedIndex]} href={photoItem[selectedIndex].href} />
                </Grid>

            </Grid>
        );
    } else {
        return null;
    }

}