import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: 'black',
            width: '100%',
            minWidth: '200px',
            maxWidth: '36ch',
        },
        inline: {
            display: 'inline',
        },
    }),
);


type Feature = {
    label: string,
    icon?: string,
    details?: string
}


export default function FeatureList({features, label}: {label: string, features: Feature[]}) {
    const classes = useStyles();

    return (
        <span className={classes.root}>
            <h3>{label}</h3>
            <List >
                {features.map(({label, icon, details}) => (
                    <React.Fragment>
                        <ListItem alignItems="flex-start" >
                            <ListItemAvatar>
                                <Avatar alt={label} src={icon} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={label}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            {details}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </React.Fragment>
                ))}
            </List>
        </span>
    );
}
