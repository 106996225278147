import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {HeaderBanner} from "../components/HeaderBanner";
import {PhotoGallery} from "../components/PhotoGallery";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);


type Image = {
    title?: string,
    href: string,
    description?: string
}

type GalleryPageProps = {
    title: string,
    images: Image[]
};

export function GalleryPage ({title, images}: GalleryPageProps){
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <HeaderBanner title={title} />
                </Grid>
                <Grid item xs={12}>
                    <PhotoGallery photoItem={images} />
                </Grid>
            </Grid>
        </div>
    )
}

