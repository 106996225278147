import React, {CSSProperties} from 'react';
import Paper from "@material-ui/core/Paper";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

type HeaderBannerProps = {
    title: string,
    style?: CSSProperties
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);


export const HeaderBanner = ({title, style}: HeaderBannerProps) => {
    const classes = useStyles();
    return (
        <Paper style={style} className={classes.paper}>
            <h3>{title}</h3>
        </Paper>
    )
}