import React from 'react';
import './App.css';
import {AppBar} from "@material-ui/core";
import {SimpleTabs} from "./pages/tabsPage";


function App() {
  return (
    <div className="App">
        <AppBar position="static">
            <SimpleTabs />
        </AppBar>
    </div>
  );
}

export default App;
